import AccountClaim from './AccountClaim'
import ClaimableEmailConfirmation from './ClaimableEmailConfirmation'
import EmailConfirmation from './EmailConfirmation'
import Identify from './Identify'
import LegalAcceptance from './LegalAcceptance'
import PartnerSignIn from './PartnerSignIn'
import PasswordChange from './PasswordChange'
import PasswordReset from './PasswordReset'
import PasswordUpdate from './PasswordUpdate'
import ResendEmailConfirmation from './ResendEmailConfirmation'
import SamlConfirmLogout from './SamlConfirmLogout'
import SignIn from './SignIn'
import SignUp from './SignUp'
import EmailVerification from './EmailVerification'

export default {
  AccountClaim,
  ClaimableEmailConfirmation,
  EmailConfirmation,
  Identify,
  LegalAcceptance,
  PartnerSignIn,
  PasswordChange,
  PasswordReset,
  PasswordUpdate,
  ResendEmailConfirmation,
  SamlConfirmLogout,
  SignIn,
  SignUp,
  EmailVerification,
}
