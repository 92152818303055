import { shape, string } from 'prop-types'

const samlConfirmLogoutShape = shape({
  links: shape({
    logout: string.isRequired,
    samlLogout: string.isRequired,
  }),
  partner: {
    name: string.isRequired,
  },
})

export default samlConfirmLogoutShape
