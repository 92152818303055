import { defineMessages } from 'react-intl'

export default defineMessages({
  suggestion: {
    id: 'PasswordWarning.suggestion',
    defaultMessage: 'Password Suggestions',
  },
  try: {
    id: 'PasswordWarning.try',
    defaultMessage: 'Please try something',
  },
  complex: {
    id: 'PasswordWarning.complex',
    defaultMessage: 'more complex',
  },
})
