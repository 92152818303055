import countries from 'i18n-iso-countries'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/it.json'))
countries.registerLocale(require('i18n-iso-countries/langs/th.json'))
countries.registerLocale(require('i18n-iso-countries/langs/ja.json'))

const updateCountryNames = (locale, isoCountries) => {
  let listOfCountries = isoCountries

  if (locale === 'th') {
    const updatedCountryNames = {
      WF: 'วาลลิสและฟูตูนา',
      BL: 'แซ็งบาร์เตเลมี',
      HK: 'ฮ่องกง',
      MM: 'เมียนมา [พม่า]',
      MO: 'มาเก๊า',
      CZ: 'เช็กเกีย',
      SX: 'ซินต์มาร์เติน',
    }

    listOfCountries = { ...listOfCountries, ...updatedCountryNames }
  }

  return listOfCountries
}

const supportedCountries = (locale) => {
  const currentLocal = locale === 'fr' ? 'en' : locale
  const isoCountries = countries.getNames(currentLocal, { select: 'official' })
  const listOfCountries = updateCountryNames(currentLocal, isoCountries)
  const entries = Object.entries(listOfCountries)

  return (
    entries.map(country => (
      {
        name: country[1],
        shortCode: country[0],
      }
    )).sort((a, b) => a.name.localeCompare(b.name))
  )
}

export default supportedCountries
