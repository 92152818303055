import { defineMessages } from 'react-intl'

export default defineMessages({
  review: {
    id: 'LegalAcceptance.review',
    defaultMessage: 'To continue, please review and accept our updated terms and policies.',
  },
  connector: {
    id: 'LegalAcceptance.connector',
    defaultMessage: 'and',
  },
  our: {
    id: 'LegalAcceptance.our',
    defaultMessage: 'Our',
  },
  one: {
    id: 'LegalAcceptance.one',
    defaultMessage: '{our} {policyNameList} Has Changed',
  },
  other: {
    id: 'LegalAcceptance.other',
    defaultMessage: '{our} {policyNameList} Have Changed',
  },
})
