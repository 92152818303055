import React from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'

// eslint-disable-next-line global-require, import/no-dynamic-require
const localePath = fileName => require(`../../locales/${fileName}.json`)

const Translator = ({ locale, children }) => (
  <IntlProvider locale={locale} messages={localePath(locale)}>
    {children}
  </IntlProvider>
)

Translator.propTypes = {
  locale: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Translator
