import { defineMessages } from 'react-intl'

export const getFeedbackMessages = (intl, messages) => ({
  use_a_few_words: intl.formatMessage(messages.feedbackUseAFewWords),
  no_need_for_mixed_chars: intl.formatMessage(messages.feedbackNoNeedForMixedChars),
  uncommon_words_are_better: intl.formatMessage(messages.feedbackUncommonWordsAreBetter),
  straight_rows_of_keys_are_easy: intl.formatMessage(messages.feedbackStraightRowsOfKeysAreEasy),
  short_keyboard_patterns_are_easy: intl.formatMessage(messages.feedbackShortKeyboardPatternsAreEasy),
  use_longer_keyboard_patterns: intl.formatMessage(messages.feedbackUseLongerKeyboardPatterns),
  repeated_chars_are_easy: intl.formatMessage(messages.feedbackRepeatedCharsAreEasy),
  repeated_patterns_are_easy: intl.formatMessage(messages.feedbackRepeatedPatternsAreEasy),
  avoid_repeated_chars: intl.formatMessage(messages.feedbackAvoidRepeatedChars),
  sequences_are_easy: intl.formatMessage(messages.feedbackSequencesAreEasy),
  avoid_sequences: intl.formatMessage(messages.feedbackAvoidSequences),
  recent_years_are_easy: intl.formatMessage(messages.feedbackRecentYearsAreEasy),
  avoid_recent_years: intl.formatMessage(messages.feedbackAvoidRecentYears),
  avoid_associated_years: intl.formatMessage(messages.feedbackAvoidAssociatedYears),
  dates_are_easy: intl.formatMessage(messages.feedbackDatesAreEasy),
  avoid_associated_dates_and_years: intl.formatMessage(messages.feedbackAvoidAssociatedDatesAndYears),
  top10_common_password: intl.formatMessage(messages.feedbackTop10CommonPassword),
  top100_common_password: intl.formatMessage(messages.feedbackTop100CommonPassword),
  very_common_password: intl.formatMessage(messages.feedbackVeryCommonPassword),
  similar_to_common_password: intl.formatMessage(messages.feedbackSimilarToCommonPassword),
  a_word_is_easy: intl.formatMessage(messages.feedbackAWordIsEasy),
  names_are_easy: intl.formatMessage(messages.feedbackNamesAreEasy),
  common_names_are_easy: intl.formatMessage(messages.feedbackCommonNamesAreEasy),
  capitalization_doesnt_help: intl.formatMessage(messages.feedbackCapitalizationDoesntHelp),
  all_uppercase_doesnt_help: intl.formatMessage(messages.feedbackAllUppercaseDoesnthelp),
  reverse_doesnt_help: intl.formatMessage(messages.feedbackReverseDoesntHelp),
  substitution_doesnt_help: intl.formatMessage(messages.feedbackSubstitutionDoesntHelp),
  user_dictionary: intl.formatMessage(messages.feedbackUserDictionary),
})

export default defineMessages({
  label: {
    id: 'PasswordInput.label',
    defaultMessage: 'Password',
  },
  validationMessage: {
    id: 'PasswordInput.validationMessage',
    defaultMessage: "Password can't be blank",
  },
  feedbackUseAFewWords: {
    id: 'PasswordInput.feedbackUseAFewWords',
    defaultMessage: 'Use a few words, avoid common phrases',
  },
  feedbackNoNeedForMixedChars: {
    id: 'PasswordInput.feedbackNoNeedForMixedChars',
    defaultMessage: 'No need for symbols, digits, or uppercase letters',
  },
  feedbackUncommonWordsAreBetter: {
    id: 'PasswordInput.feedbackUncommonWordsAreBetter',
    defaultMessage: 'Add another word or two. Uncommon words are better.',
  },
  feedbackStraightRowsOfKeysAreEasy: {
    id: 'PasswordInput.feedbackStraightRowsOfKeysAreEasy',
    defaultMessage: 'Straight rows of keys are easy to guess',
  },
  feedbackShortKeyboardPatternsAreEasy: {
    id: 'PasswordInput.feedbackShortKeyboardPatternsAreEasy',
    defaultMessage: 'Short keyboard patterns are easy to guess',
  },
  feedbackUseLongerKeyboardPatterns: {
    id: 'PasswordInput.feedbackUseLongerKeyboardPatterns',
    defaultMessage: 'Use a longer keyboard pattern with more turns',
  },
  feedbackRepeatedCharsAreEasy: {
    id: 'PasswordInput.feedbackRepeatedCharsAreEasy',
    defaultMessage: 'Repeats like "aaa" are easy to guess',
  },
  feedbackRepeatedPatternsAreEasy: {
    id: 'PasswordInput.feedbackRepeatedPatternsAreEasy',
    defaultMessage: 'Repeats like "abcabcabc" are only slightly harder to guess than "abc"',
  },
  feedbackAvoidRepeatedChars: {
    id: 'PasswordInput.feedbackAvoidRepeatedChars',
    defaultMessage: 'Avoid repeated words and characters',
  },
  feedbackSequencesAreEasy: {
    id: 'PasswordInput.feedbackSequencesAreEasy',
    defaultMessage: 'Sequences like "abc" or "6543" are easy to guess',
  },
  feedbackAvoidSequences: {
    id: 'PasswordInput.feedbackAvoidSequences',
    defaultMessage: 'Avoid sequences',
  },
  feedbackRecentYearsAreEasy: {
    id: 'PasswordInput.feedbackRecentYearsAreEasy',
    defaultMessage: 'Recent years are easy to guess',
  },
  feedbackAvoidRecentYears: {
    id: 'PasswordInput.feedbackAvoidRecentYears',
    defaultMessage: 'Avoid recent years',
  },
  feedbackAvoidAssociatedYears: {
    id: 'PasswordInput.feedbackAvoidAssociatedYears',
    defaultMessage: 'Avoid years that are associated with you',
  },
  feedbackDatesAreEasy: {
    id: 'PasswordInput.feedbackDatesAreEasy',
    defaultMessage: 'Dates are often easy to guess',
  },
  feedbackAvoidAssociatedDatesAndYears: {
    id: 'PasswordInput.feedbackAvoidAssociatedDatesAndYears',
    defaultMessage: 'Avoid dates and years that are associated with you',
  },
  feedbackTop10CommonPassword: {
    id: 'PasswordInput.feedbackTop10CommonPassword',
    defaultMessage: 'This is a top-10 common password',
  },
  feedbackTop100CommonPassword: {
    id: 'PasswordInput.feedbackTop100CommonPassword',
    defaultMessage: 'This is a top-100 common password',
  },
  feedbackVeryCommonPassword: {
    id: 'PasswordInput.feedbackVeryCommonPassword',
    defaultMessage: 'This is a very common password',
  },
  feedbackSimilarToCommonPassword: {
    id: 'PasswordInput.feedbackSimilarToCommonPassword',
    defaultMessage: 'This is similar to a commonly used password',
  },
  feedbackAWordIsEasy: {
    id: 'PasswordInput.feedbackAWordIsEasy',
    defaultMessage: 'A word by itself is easy to guess',
  },
  feedbackNamesAreEasy: {
    id: 'PasswordInput.feedbackNamesAreEasy',
    defaultMessage: 'Names and surnames by themselves are easy to guess',
  },
  feedbackCommonNamesAreEasy: {
    id: 'PasswordInput.feedbackCommonNamesAreEasy',
    defaultMessage: 'Common names and surnames are easy to guess',
  },
  feedbackCapitalizationDoesntHelp: {
    id: 'PasswordInput.feedbackCapitalizationDoesntHelp',
    defaultMessage: "Capitalization doesn't help very much",
  },
  feedbackAllUppercaseDoesnthelp: {
    id: 'PasswordInput.feedbackAllUppercaseDoesnthelp',
    defaultMessage: 'All-uppercase is almost as easy to guess as all-lowercase',
  },
  feedbackReverseDoesntHelp: {
    id: 'PasswordInput.feedbackReverseDoesntHelp',
    defaultMessage: "Reversed words aren't much harder to guess",
  },
  feedbackSubstitutionDoesntHelp: {
    id: 'PasswordInput.feedbackSubstitutionDoesntHelp',
    defaultMessage: 'Predictable substitutions like "@" instead of "a" don\'t help very much',
  },
  feedbackUserDictionary: {
    id: 'PasswordInput.feedbackUserDictionary',
    defaultMessage: 'This password is on the blacklist',
  },
})
