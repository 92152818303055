import { defineMessages } from 'react-intl'

export default defineMessages({
  contentTitle: {
    id: 'SamlConfirmLogout.contentTitle',
    defaultMessage: 'Confirm Logout',
  },
  selectOption: {
    id: 'SamlConfirmLogout.selectOption',
    defaultMessage: 'Please select your logout option.',
  },
  logout: {
    id: 'SamlConfirmLogout.logout',
    defaultMessage: 'Log out',
  },
  logoutAll: {
    id: 'SamlConfirmLogout.logoutAll',
    defaultMessage: 'Log out of all systems*',
  },
  logoutAllMessage: {
    id: 'SamlConfirmLogout.logoutAllMessage',
    defaultMessage: '*This will log you out of all systems associated with your',
  },
  logoutAllMessageCont: {
    id: 'SamlConfirmLogout.logoutAllMessageCont',
    defaultMessage: 'account on this device',
  },
})
