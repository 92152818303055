import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'

import FormContext from 'contexts/formContext'

class FormInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialValue: props.initialValue,
      validateTrigger: false,
      touched: false,
    }
  }

  validateOnBlur = () => {
    const { name, validateFields } = this.props

    validateFields([name], (errors) => {
      this.setState({ touched: true })
      if (errors != null) {
        this.setState({ validateTrigger: 'onChange' })
      }
    })
  }

  handleOnBlur = (event) => {
    const { value } = event.target
    const { touched } = this.state

    if (value.length > 0 || touched) {
      this.validateOnBlur()
    }
  }

  handleOnChange = () => {
    const { submittedForm } = this.context

    if (submittedForm) {
      this.setState({ validateTrigger: 'onChange' })
    }
  }

  render() {
    const {
      getFieldDecorator,
      rules,
      testHandle,
      label,
      name,
      type,
      placeholder,
      autoFocus,
      disabled,
    } = this.props
    const { validateTrigger, initialValue } = this.state

    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, {
          initialValue,
          validateTrigger,
          rules,
        })(
          <Input
            name={name}
            type={type}
            placeholder={placeholder}
            data-test={testHandle}
            autoFocus={autoFocus}
            disabled={disabled}
            onBlur={this.handleOnBlur}
            onChange={this.handleOnChange}
          />,
        )}
      </Form.Item>
    )
  }
}

FormInput.contextType = FormContext

FormInput.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  validateFields: PropTypes.func.isRequired,
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      url: PropTypes.string,
    }),
    PropTypes.shape({
      required: PropTypes.string,
      message: PropTypes.string,
    }),
  ).isRequired,
  testHandle: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
}

FormInput.defaultProps = {
  disabled: false,
  autoFocus: false,
  initialValue: '',
}

export default FormInput
