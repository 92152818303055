import React from 'react'
import { Row, Col } from 'antd'
import { Heading, Logo } from '@generalassembly/cog'

import styles from './styles.module.less'

const MastheadLogo = () => (
  <div className={styles.mastheadLogo}>
    <Row type="flex">
      <Col>
        <a href="/" className={styles.gaLogo}>
          <Logo />
        </a>
        <Heading className={styles.gaTitle} type="paragraph-header">
          General Assembly
        </Heading>
      </Col>
    </Row>
  </div>
)

export default MastheadLogo
