import React, { useContext } from 'react'
import { message, Form } from 'antd'
import FormContext from 'contexts/formContext'
import DOMPurify from 'dompurify'

const Flash = React.memo(() => {
  const formContext = useContext(FormContext)
  return (
    <Form.Item>
      {formContext.flash.map(flashMessage => message.open({
        // eslint-disable-next-line react/no-danger
        content: <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(flashMessage.message) }} />,
        type: flashMessage.type,
        duration: 0,
      }))}
    </Form.Item>
  )
})

export default Flash
