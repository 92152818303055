import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.less'

const PasswordMeter = ({ score = 0 }) => {
  let colorClassName

  if (score > 1) {
    colorClassName = styles.success
  } else if (score === 1) {
    colorClassName = styles.fail
  }

  return (
    <div className={styles.meter}>
      <span className={classNames(styles.strong, score > 3 && colorClassName)} />
      <span className={classNames(styles.good, score > 2 && colorClassName)} />
      <span className={classNames(styles.average, score > 1 && colorClassName)} />
      <span className={classNames(styles.weak, score > 0 && colorClassName)} />
    </div>
  )
}

PasswordMeter.propTypes = {
  score: PropTypes.number,
}

PasswordMeter.defaultProps = {
  score: 0,
}

export default PasswordMeter
