import { defineMessages } from 'react-intl'

export default defineMessages({
  contentTitle: {
    id: 'PasswordReset.contentTitle',
    defaultMessage: 'Forgot your password?',
  },
  title: {
    id: 'PasswordReset.title',
    defaultMessage: 'Enter your email address, and we’ll send you a link to create a new password.',
  },
})
