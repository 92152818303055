import React from 'react'
import PropTypes from 'prop-types'
import {
  Row, Col, Button,
} from 'antd'
import { Box, Paragraph } from '@generalassembly/cog'
import { injectIntl, FormattedMessage } from 'react-intl'

import Page from 'components/Page'
import Content from 'components/Content'

import messages from './messages'

const EmailConfirmation = ({ links, user, intl }) => (
  <Page>
    <Content title={intl.formatMessage(messages.contentTitle)}>
      <Paragraph margin="stack-md">
        <FormattedMessage
          {...messages.notifyConfirmation}
          values={{
            email: <b>{user.email}</b>,
          }}
        />
        {' '}
        <FormattedMessage {...messages.instruction} />
      </Paragraph>
      <Row>
        <Col sm={12}>
          <Box margin="stack-xs">
            <a
              href={links.resendConfirmation}
              data-method="post"
              data-test="email_confirmation_resend"
            >
              <FormattedMessage {...messages.resend} />
            </a>
          </Box>
        </Col>
        <Col sm={12}>
          <Box margin="stack-xs">
            <Button
              block
              type="primary"
              href={links.signIn}
              data-test="email_confirmation_sign_in"
            >
              <FormattedMessage {...messages.signInUrl} />
            </Button>
          </Box>
        </Col>
      </Row>
    </Content>
  </Page>
)

EmailConfirmation.propTypes = {
  links: PropTypes.shape({
    resendConfirmation: PropTypes.string.isRequired,
    signIn: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
}

export default injectIntl(EmailConfirmation)
