import { defineMessages } from 'react-intl'

export default defineMessages({
  firstNameLabel: {
    id: 'FullNameInput.firstNameLabel',
    defaultMessage: 'First Name',
  },
  firstNamePlaceholder: {
    id: 'FullNameInput.firstNamePlaceholder',
    defaultMessage: 'First Name',
  },
  firstNameValidationMessage: {
    id: 'FullNameInput.firstNameValidationMessage',
    defaultMessage: 'First Name can\'t be blank',
  },
  lastNameLabel: {
    id: 'FullNameInput.lastNameLabel',
    defaultMessage: 'Last Name',
  },
  lastNamePlaceholder: {
    id: 'FullNameInput.lastNamePlaceholder',
    defaultMessage: 'Last Name',
  },
  lastNameValidationMessage: {
    id: 'FullNameInput.lastNameValidationMessage',
    defaultMessage: 'Last Name can\'t be blank',
  },
})
