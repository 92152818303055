import { defineMessages } from 'react-intl'

export default defineMessages({
  condition: {
    id: 'LegalOptIn.condition',
    defaultMessage: 'Terms and conditions must be accepted',
  },
  privacy: {
    id: 'LegalOptIn.privacy',
    defaultMessage: 'Privacy Policy',
  },
  terms: {
    id: 'LegalOptIn.terms',
    defaultMessage: 'Terms of Service',
  },
  agree: {
    id: 'LegalOptIn.agree',
    defaultMessage: 'By providing us with your email, you agree to General Assembly’s',
  },
  unsubscribe: {
    id: 'LegalOptIn.unsubscribe',
    defaultMessage: 'and to receive email updates on courses, special events and GA news. You can change your mind at any time and unsubscribe from GA marketing emails by clicking the “unsubscribe” link located at the bottom of every marketing email or by emailing',
  },
  acknowledge: {
    id: 'LegalOptIn.acknowledge',
    defaultMessage: 'I have read and acknowledge General Assembly’s {privacyPolicy}',
  },
})
