import { defineMessages } from 'react-intl'

export default defineMessages({
  validationMessage: {
    id: 'CountrySelect.validationMessage',
    defaultMessage: "Location can't be blank",
  },
  label: {
    id: 'CountrySelect.label',
    defaultMessage: 'Location',
  },
  placeholder: {
    id: 'CountrySelect.placeholder',
    defaultMessage: 'Select one',
  },
})
