import React from 'react'
import PropTypes from 'prop-types'
import {
  Form, Checkbox, Input, Popover,
} from 'antd'
import { Paragraph } from '@generalassembly/cog'
import { injectIntl, FormattedMessage } from 'react-intl'

import LegalOptInModal from 'components/LegalOptInModal'

import styles from './styles.module.less'
import messages from './messages'

class LegalOptIn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      acceptedTermsAndConditions: props.initialValue,
      isChecked: false,
    }
  }

  handleCheckChange = (_rule, newValue, callback) => {
    const { value, intl } = this.props

    if (newValue === false) {
      this.setState({ acceptedTermsAndConditions: '', isChecked: false })
      callback(intl.formatMessage(messages.condition))
    } else {
      this.setState({ acceptedTermsAndConditions: value || '1', isChecked: true })
      callback()
    }
  }

  renderPopover = (title, content) => {
    const modal = <LegalOptInModal title={title} content={content} />
    return (
      <Popover
        content={modal}
        trigger="click"
        placement="bottom"
        className={styles.legalPopover}
      >
        <span className={styles.popoverLink}>{title}</span>
      </Popover>
    )
  }

  renderTermsSection() {
    const { consentModalContent, intl } = this.props
    return (
      <Paragraph size="small" padding="horizontal-md" margin="stack-md">
        <FormattedMessage {...messages.agree} />
        {' '}
        {this.renderPopover(
          intl.formatMessage(messages.terms),
          consentModalContent.termsOfService,
        )}
        {' '}
        <FormattedMessage {...messages.unsubscribe} />
        {' '}
        <a href="mailto: no.marketing@ga.co" className={styles.optOutEmail}>
          no.marketing@ga.co.
        </a>
      </Paragraph>
    )
  }

  render() {
    const {
      getFieldDecorator,
      name,
      testHandle,
      consentModalContent,
      intl,
    } = this.props

    const { acceptedTermsAndConditions, isChecked } = this.state

    return (
      <div className={styles.wrapper}>
        <Form.Item>
          <Input
            name={name}
            type="hidden"
            value={acceptedTermsAndConditions}
          />
          {getFieldDecorator('terms', {
            initialValue: isChecked,
            valuePropName: 'checked',
            rules: [
              {
                required: true,
                message: intl.formatMessage(messages.condition),
              },
              {
                validator: this.handleCheckChange,
              },
            ],
          })(
            <Checkbox className={styles.checkbox} data-test={testHandle}>
              <FormattedMessage
                {...messages.acknowledge}
                values={{
                  privacyPolicy: this.renderPopover(
                    intl.formatMessage(messages.privacy),
                    consentModalContent.privacyPolicy,
                  ),
                }}
              />
            </Checkbox>,
          )}
        </Form.Item>
        {this.renderTermsSection()}
      </div>
    )
  }
}

LegalOptIn.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  testHandle: PropTypes.string.isRequired,
  initialValue: PropTypes.string.isRequired,
  consentModalContent: PropTypes.shape({
    privacyPolicy: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
    termsOfService: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
  }).isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
}

LegalOptIn.defaultProps = {
  value: '',
}

export default injectIntl(LegalOptIn)
