import React from 'react'
import {
  Form, Button, Col, Row,
} from 'antd'
import { Box } from '@generalassembly/cog'
import { injectIntl, FormattedMessage } from 'react-intl'

import BaseForm from 'components/BaseForm'
import PasswordInput from 'components/PasswordInput'
import PasswordConfirmationInput from 'components/PasswordConfirmationInput'

import passwordUpdateShape from 'shapes/passwordUpdateShape'
import messages from './messages'

const PasswordUpdateForm = ({ form, formConfig, intl }) => {
  const { getFieldDecorator, validateFields } = form
  const passwordHandle = 'password'

  return (
    <BaseForm form={form}>
      <input
        type="hidden"
        name="user[reset_password_token]"
        value={formConfig.resetPasswordToken}
      />
      <Row>
        <Col>
          <PasswordInput
            name="user[password]"
            testHandle="password_update_password"
            getFieldDecorator={getFieldDecorator}
            validateFields={validateFields}
            passwordHandle={passwordHandle}
            placeholder={intl.formatMessage(messages.passwordInputPlaceholder)}
            minValidScore={formConfig.passwordMinScore}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <PasswordConfirmationInput
            name="user[password_confirmation]"
            testHandle="password_update_password_confirmation"
            getFieldDecorator={getFieldDecorator}
            passwordHandle={passwordHandle}
            placeholder={intl.formatMessage(messages.passwordConfirmationInputPlaceholder)}
            form={form}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={{ span: 12, offset: 12 }}>
          <Box padding="vertical-xs">
            <Button
              block
              type="primary"
              htmlType="submit"
              data-test="password_update_submit"
            >
              <FormattedMessage {...messages.button} />
            </Button>
          </Box>
        </Col>
      </Row>
    </BaseForm>
  )
}

PasswordUpdateForm.propTypes = passwordUpdateShape.isRequired

export default Form.create({ name: 'password_update' })(injectIntl(PasswordUpdateForm))
