import { defineMessages } from 'react-intl'

export default defineMessages({
  passwordPlaceholder: {
    id: 'AccountClaimForm.passwordPlaceholder',
    defaultMessage: 'Password',
  },
  passwordConfirmationPlaceholder: {
    id: 'AccountClaimForm.passwordConfirmationPlaceholder',
    defaultMessage: 'Confirm',
  },
  button: {
    id: 'AccountClaimForm.button',
    defaultMessage: 'Create Account and Continue',
  },
})
