import { defineMessages } from 'react-intl'

export default defineMessages({
  button: {
    id: 'IdentifyForm.button',
    defaultMessage: 'Continue',
  },
  remember: {
    id: 'IdentifyForm.remember',
    defaultMessage: 'Remember me for two weeks.',
  },
  footer: {
    id: 'IdentifyForm.footer',
    defaultMessage: 'Don’t have an account?',
  },
  signUpLink: {
    id: 'IdentifyForm.signUpLink',
    defaultMessage: 'Sign up.',
  },
  agree: {
    id: 'IdentifyForm.agree',
    defaultMessage: 'By providing us with your email, you agree to the terms of our {privacyPolicy} and {termsOfService}.',
  },
  privacy: {
    id: 'IdentifyForm.privacy',
    defaultMessage: 'Privacy Policy',
  },
  terms: {
    id: 'IdentifyForm.terms',
    defaultMessage: 'Terms of Service',
  },
})
