import { defineMessages } from 'react-intl'

export default defineMessages({
  passwordLabel: {
    id: 'SignInForm.passwordLabel',
    defaultMessage: 'Password',
  },
  passwordPlaceholder: {
    id: 'SignInForm.passwordPlaceholder',
    defaultMessage: 'Your Password',
  },
  passwordValidator: {
    id: 'SignInForm.passwordValidator',
    defaultMessage: "Password can't be blank",
  },
  remember: {
    id: 'SignInForm.remember',
    defaultMessage: 'Remember me for two weeks.',
  },
  button: {
    id: 'SignInForm.button',
    defaultMessage: 'Continue',
  },
  forgotPassword: {
    id: 'SignInForm.forgotPassword',
    defaultMessage: 'Forgot password?',
  },
  switch: {
    id: 'SignInForm.switch',
    defaultMessage: 'Switch account.',
  },
})
