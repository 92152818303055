import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Paragraph, Heading, Box } from '@generalassembly/cog'

import styles from './styles.module.less'

class LegalOptInModal extends React.Component {
  render() {
    const { title, content } = this.props

    return (
      <Fragment>
        <Box padding="inset-xs">
          <Heading type="paragraph-header" margin="stack-sm">
            {title}
          </Heading>
          <Paragraph size="medium" margin="stack" className={styles.paragraph}>
            {content.map((item, i) => {
              // eslint-disable-next-line no-prototype-builtins
              if (item.hasOwnProperty('link')) {
                return (
                  <a
                    key={i} // eslint-disable-line react/no-array-index-key
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.legalDocLink}
                  >
                    {' '}
                    {item.content}
                  </a>
                )
              }
              return `${item.content}`
            })}
          </Paragraph>
        </Box>
      </Fragment>
    )
  }
}

LegalOptInModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
    }),
  ).isRequired,
}

export default LegalOptInModal
