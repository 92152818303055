import {
  shape,
  string,
  bool,
  number,
  arrayOf,
  oneOf,
} from 'prop-types'

export const flashShape = arrayOf(
  shape({
    type: string,
    message: string,
  }),
)

export const formSubmitShape = shape({
  authenticityToken: string.isRequired,
  action: string.isRequired,
  method: oneOf(['put', 'patch']),
})

export const passwordConfigShape = shape({
  minScore: number.isRequired,
})

export const legalOptInShape = shape({
  legalOptIn: bool.isRequired,
})

export const requiredDocumentsShape = arrayOf(
  shape({
    name: string.isRequired,
    url: string.isRequired,
  }),
)
