import { defineMessages } from 'react-intl'

export default defineMessages({
  label: {
    id: 'PasswordConfirmationInput.label',
    defaultMessage: 'Password Confirmation',
  },
  validationMessage: {
    id: 'PasswordConfirmationInput.validationMessage',
    defaultMessage: "Password confirmation can't be blank",
  },
  validationPasswordMatchMessage: {
    id: 'PasswordConfirmationInput.validationPasswordMatchMessage',
    defaultMessage: "Password confirmation doesn't match Password",
  },
})
