import React from 'react'
import {
  Button, Col, Form, Row,
} from 'antd'
import { Box } from '@generalassembly/cog'
import { FormattedMessage } from 'react-intl'

import BaseForm from 'components/BaseForm'
import EmailInput from 'components/EmailInput'

import partnerSignInShape from 'shapes/partnerSignInShape'
import messages from './messages'

const PartnerSignInForm = ({ form, email }) => {
  const { getFieldDecorator, validateFields } = form

  return (
    <BaseForm form={form}>
      <EmailInput
        getFieldDecorator={getFieldDecorator}
        validateFields={validateFields}
        testHandle="partner_sign_in_email"
        name="email"
        initialValue={email}
        autoFocus
      />
      <Row>
        <Col>
          <Box padding="vertical-xs">
            <Button
              block
              type="primary"
              htmlType="submit"
              data-test="partner_sign_in_submit"
            >
              <FormattedMessage {...messages.button} />
            </Button>
          </Box>
        </Col>
      </Row>
    </BaseForm>
  )
}

PartnerSignInForm.propTypes = partnerSignInShape.isRequired

export default Form.create({ name: 'partner_sign_in' })(PartnerSignInForm)
