import React from 'react'
import { Box } from '@generalassembly/cog'
import { injectIntl } from 'react-intl'

import Page from 'components/Page'
import Content from 'components/Content'
import FormContext from 'contexts/formContext'
import PasswordUpdateForm from 'forms/PasswordUpdateForm'

import passwordUpdateShape from 'shapes/passwordUpdateShape'
import messages from './messages'

const PasswordUpdate = ({
  formSubmit, flash, formConfig, intl,
}) => (
  <Page>
    <Content title={intl.formatMessage(messages.contentTitle)}>
      <Box margin="stack-sm">
        <FormContext.Provider value={{ formSubmit, flash }}>
          <PasswordUpdateForm formConfig={formConfig} />
        </FormContext.Provider>
      </Box>
    </Content>
  </Page>
)

PasswordUpdate.propTypes = passwordUpdateShape.isRequired

export default injectIntl(PasswordUpdate)
