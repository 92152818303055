import { shape, string, bool } from 'prop-types'
import { passwordConfigShape } from 'shapes'

const passwordChangeShape = shape({
  formConfig: shape({
    hasLegalDocumentToAccept: bool.isRequired,
    reason: string.isRequired,
  }),
  user: shape({
    countryOfResidence: string.isRequired,
    agreeTermsAndConditions: string.isRequired,
  }),
  password: passwordConfigShape,
})

export default passwordChangeShape
