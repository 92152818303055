import { defineMessages } from 'react-intl'

export default defineMessages({
  button: {
    id: 'PasswordUpdateForm.button',
    defaultMessage: 'Change Password',
  },
  passwordInputPlaceholder: {
    id: 'PasswordUpdateForm.passwordInputPlaceholder',
    defaultMessage: 'Password',
  },
  passwordConfirmationInputPlaceholder: {
    id: 'PasswordUpdateForm.passwordConfirmationInputPlaceholder',
    defaultMessage: 'Confirm',
  },
})
