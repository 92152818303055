import { defineMessages } from 'react-intl'

export default defineMessages({
  contentTitle: {
    id: 'PasswordChange.contentTitle',
    defaultMessage: 'Update Your Password',
  },
  modalTitle: {
    id: 'PasswordChangeMessage.modalTitle',
    defaultMessage: 'Passwords And Phrases',
  },
  modalRecommend: {
    id: 'PasswordChangeMessage.recommend',
    defaultMessage: 'We recommend using a phrase or sentence composed of words in unexpected',
  },
  modalCombination: {
    id: 'PasswordChangeMessage.combination',
    defaultMessage: 'combinations. Traditional password requirements (i.e. including',
  },
  modalExample: {
    id: 'PasswordChangeMessage.example',
    defaultMessage: "symbols, numbers, capital letters, etc.) don't always create strong passwords.",
  },
  createStrongerPasswordInstruction: {
    id: 'PasswordChangeMessage.createStrongerPasswordInstruction',
    defaultMessage: 'Please create a stronger password or',
  },
  createStrongerPasswordWithsecureComplex: {
    id: 'PasswordChangeMessage.createStrongerPasswordWithsecureComplex',
    values: { createStrongerPasswordWithsecureComplex: ' ' },
    defaultMessage: '{createStrongerPasswordWithsecureComplex}',
  },
  secure: {
    id: 'PasswordChangeMessage.secure',
    defaultMessage: 'to keep your profile secure.',
  },
  modalUrl: {
    id: 'PasswordChangeMessage.modalUrl',
    defaultMessage: 'passphrase',
  },
  createPasswordInstruction: {
    id: 'PasswordChangeMessage.createPasswordInstruction',
    defaultMessage: 'Please create a password or',
  },
  instruction: {
    id: 'PasswordChangeMessage.instruction',
    defaultMessage: 'Please update your password to continue.',
  },
  secureComplex: {
    id: 'PasswordChangeMessage.secureComplex',
    defaultMessage: 'to keep your profile secure.',
  },
})
