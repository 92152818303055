import React from 'react'
import PropTypes from 'prop-types'
import Bowser from 'bowser'
import Translator from '../../components/Translator'
import ComponentList from '..'
import BrowserWarning from '../../components/BrowserWarning'

// @NOTE: Currently configured for 2015+ versions
// Except for IE11 due to no automatic updates.
const supportedBrowsers = {
  chrome: '>=40',
  firefox: '>=36',
  ie: '11',
  safari: '>=9',
  edge: '>=15',
}

const renderIntlComponent = (props) => {
  const { componentId, locale } = props
  const browser = Bowser.getParser(window.navigator.userAgent)
  const isValidBrowser = browser.satisfies(supportedBrowsers)
  const unsupportedBrowserUserAgent = browser.test(/(XiaoMi\/MiuiBrowser)|(XiaoMi\/Mint Browser)/)

  if (!isValidBrowser || unsupportedBrowserUserAgent) {
    return (
      <BrowserWarning />
    )
  }

  return (
    <Translator locale={locale}>
      {React.createElement(ComponentList[componentId], { ...props })}
    </Translator>
  )
}

const App = props => (
  <React.Fragment>
    {renderIntlComponent(props)}
  </React.Fragment>
)

renderIntlComponent.propTypes = {
  componentId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
}

export default App
