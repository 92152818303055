import React, { Fragment } from 'react'
import {
  Form, Button, Col, Row,
} from 'antd'
import { Box } from '@generalassembly/cog'
import { injectIntl, FormattedMessage } from 'react-intl'

import BaseForm from 'components/BaseForm'
import PasswordInput from 'components/PasswordInput'
import PasswordConfirmationInput from 'components/PasswordConfirmationInput'
import LegalOptIn from 'components/LegalOptIn'
import CountrySelect from 'components/CountrySelect'

import passwordChangeShape from 'shapes/passwordChangeShape'
import messages from './messages'

const PasswordChangeForm = ({
  form, user, password, formConfig, consentModalContent, intl,
}) => {
  const { countryOfResidence, agreeTermsAndConditions } = user
  const { hasLegalDocumentToAccept } = formConfig
  const { getFieldDecorator, validateFields } = form
  const passwordHandle = 'password'

  return (
    <BaseForm form={form}>
      <Row>
        <Col>
          <PasswordInput
            name="user[password]"
            testHandle="password_change_password"
            getFieldDecorator={getFieldDecorator}
            validateFields={validateFields}
            passwordHandle={passwordHandle}
            placeholder={intl.formatMessage(messages.passwordPlaceholder)}
            minValidScore={password.minScore}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <PasswordConfirmationInput
            name="user[password_confirmation]"
            testHandle="password_change_password_confirmation"
            getFieldDecorator={getFieldDecorator}
            passwordHandle={passwordHandle}
            placeholder={intl.formatMessage(messages.passwordConfirmationPlaceholder)}
            form={form}
          />
        </Col>
      </Row>

      {hasLegalDocumentToAccept && (
        <Fragment>
          <CountrySelect
            name="user[country_of_residence]"
            testHandle="password_change_country_of_residence"
            getFieldDecorator={getFieldDecorator}
            initialValue={countryOfResidence}
          />
          <LegalOptIn
            getFieldDecorator={getFieldDecorator}
            name="agree"
            value="yes"
            initialValue={agreeTermsAndConditions}
            testHandle="password_change_terms_and_conditions"
            consentModalContent={consentModalContent}
          />
        </Fragment>
      )}

      <Row>
        <Col sm={{ span: 12, offset: 12 }}>
          <Box padding="vertical-xs">
            <Button
              block
              type="primary"
              htmlType="submit"
              data-test="password_change_submit"
            >
              <FormattedMessage {...messages.button} />
            </Button>
          </Box>
        </Col>
      </Row>
    </BaseForm>
  )
}

PasswordChangeForm.propTypes = passwordChangeShape.isRequired

export default Form.create({ name: 'password_change' })(injectIntl(PasswordChangeForm))
