import React from 'react'
import { Box } from '@generalassembly/cog'
import { injectIntl } from 'react-intl'

import Page from 'components/Page'
import Content from 'components/Content'
import FormContext from 'contexts/formContext'
import PasswordChangeForm from 'forms/PasswordChangeForm'

import passwordChangeShape from 'shapes/passwordChangeShape'
import PasswordChangeMessage from './passwordChangeMessage'
import messages from './messages'

const PasswordChange = ({
  formSubmit, flash, user, password, formConfig, consentModalContent, intl,
}) => (
  <Page>
    <Content title={intl.formatMessage(messages.contentTitle)}>
      <Box margin="stack-sm">
        <PasswordChangeMessage reason={formConfig.reason} />
        <FormContext.Provider value={{ formSubmit, flash }}>
          <PasswordChangeForm user={user} password={password} formConfig={formConfig} consentModalContent={consentModalContent} />
        </FormContext.Provider>
      </Box>
    </Content>
  </Page>
)

PasswordChange.propTypes = passwordChangeShape.isRequired

export default injectIntl(PasswordChange)
