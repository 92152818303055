import React from 'react'
import PropTypes from 'prop-types'
import MastheadLogo from 'components/MastheadLogo'
import styles from './styles.module.less'

const ErrorLayout = ({ children }) => (
  <div className={styles.layout}>
    <MastheadLogo />
    <div>
      {children}
    </div>
  </div>
)

ErrorLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorLayout
