import { defineMessages } from 'react-intl'

export default defineMessages({
  contentTitle: {
    id: 'ClaimableEmailConfirmation.contentTitle',
    defaultMessage: 'Complete Your Account',
  },
  notifyConfirmation: {
    id: 'ClaimableEmailConfirmation.notifyConfirmation',
    defaultMessage: 'Before you can sign in, follow the link we just sent to {email} to:',
  },
  reminder: {
    id: 'ClaimableEmailConfirmation.reminder',
    defaultMessage: "If you don't see an email from noreply@generalassemb.ly in your inbox or spam folder, contact us at",
  },
  confirmYourEmail: {
    id: 'ClaimableEmailConfirmation.confirmYourEmail',
    defaultMessage: 'Confirm your email address',
  },
  setPassword: {
    id: 'ClaimableEmailConfirmation.setPassword',
    defaultMessage: 'Set a password and select your location',
  },
  helpEmail: {
    id: 'ClaimableEmailConfirmation.helpEmail',
    defaultMessage: 'help@ga.co',
  },
})
