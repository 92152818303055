import { defineMessages } from 'react-intl'

export default defineMessages({
  contentTitle: {
    id: 'EmailVerification.contentTitle',
    defaultMessage: 'Check Your Email',
  },
  notifyConfirmation: {
    id: 'EmailVerification.notifyConfirmation',
    defaultMessage: 'Confirm your email address using the link we sent to {email}',
  },
  reminderContactUs: {
    id: 'EmailVerification.reminderContactUs',
    defaultMessage: "If you don't see an email from noreply@generalassemb.ly in your inbox or spam folder, contact us at",
  },
  reminderMarkNotSpan: {
    id: 'EmailVerification.reminderMarkNotSpam',
    defaultMessage: 'If the email is found in your spam folder, please mark the email as “not spam” and add us to your address book. This ensures any further emails from us will go directly to your inbox.',
  },
  helpEmail: {
    id: 'EmailVerification.helpEmail',
    defaultMessage: 'help@ga.co',
  },
})
