import React from 'react'
import PropTypes from 'prop-types'
import { Heading, Logo, Paragraph } from '@generalassembly/cog'
import { FormattedMessage, injectIntl } from 'react-intl'

import styles from './styles.module.less'
import messages from './messages'

class Content extends React.Component {
  renderSizeClass = (size) => {
    switch (size) {
      case 'md':
        return styles.wrapperMd
      case 'lg':
        return styles.wrapperLg
      default:
        return styles.wrapper
    }
  }

  render() {
    const {
      children, title, size, intl,
    } = this.props

    return (
      <div className={this.renderSizeClass(size)}>
        <div className={styles.box}>
          <span className={styles.logo}>
            <Logo />
          </span>
          <Heading type="section-header" margin="stack-sm">
            {title}
          </Heading>
          {children}
        </div>
        <div className={styles.help}>
          <Paragraph size="small" margin="vertical-md" color="grey-500">
            <FormattedMessage
              {...messages.help}
              values={{
                email: (
                  <a href={`mailto:${intl.formatMessage(messages.helpEmail)}`}>
                    <FormattedMessage {...messages.helpEmail} />
                  </a>),
              }}
            />
          </Paragraph>
        </div>
      </div>
    )
  }
}

Content.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
}

Content.defaultProps = {
  size: '',
}

export default injectIntl(Content)
