import React from 'react'
import { formSubmitShape, flashShape } from 'shapes'

const FormContext = React.createContext({})

FormContext.Provider.propTypes = {
  formSubmit: formSubmitShape,
  flash: flashShape,
}

export default FormContext
