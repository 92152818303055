import Honeybadger from 'honeybadger-js'

const config = {
  apiKey: process.env.HONEYBADGER_JS_API_KEY,
  revision: process.env.HEROKU_SLUG_COMMIT,
  environment: process.env.HONEYBADGER_ENV,
  breadcrumbsEnabled: true,
}

const beforeNotifyHandler = (notice) => {
  if (!notice) return

  const url = notice && notice.url ? notice.url : ''
  notice.url = url.replace( // eslint-disable-line no-param-reassign
    /reset_password_token=([^&]+)/g,
    'reset_password_token=[FILTERED]',
  )
}

export default () => {
  const honeybadger = Honeybadger.configure(config)
  Honeybadger.beforeNotify(beforeNotifyHandler)
  return honeybadger
}
