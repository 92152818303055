import React from 'react'
import FormInput from 'components/FormInput'
import { injectIntl } from 'react-intl'

import PropTypes from 'prop-types'
import messages from './messages'

const EmailInput = (props) => {
  const { intl } = props
  const rules = [
    {
      type: 'email',
      message: intl.formatMessage(messages.validationEmail),
    },
    {
      required: true,
      message: intl.formatMessage(messages.validationBlankEmail),
    },
  ]
  return (
    <FormInput
      label={intl.formatMessage(messages.label)}
      type="email"
      placeholder={intl.formatMessage(messages.placeholder)}
      rules={rules}
      {...props}
    />
  )
}

EmailInput.propTypes = {
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
}

export default injectIntl(EmailInput)
