import React from 'react'
import PropTypes from 'prop-types'
import zxcvbn from '@generalassembly/zxcvbn'
import { Form, Input } from 'antd'
import { injectIntl } from 'react-intl'

import PasswordMeter from 'components/PasswordMeter'
import PasswordWarning from 'components/PasswordWarning'

import FormContext from 'contexts/formContext'
import styles from './styles.module.less'
import messages, { getFeedbackMessages } from './messages'

class PasswordInput extends React.Component {
  state = {
    validateTrigger: false,
    touched: false,
  }

  analyzePassword = (password) => {
    const { intl } = this.props
    return zxcvbn(password, {
      feedback_messages: getFeedbackMessages(intl, messages),
    })
  }

  validateOnBlur = () => {
    const { passwordHandle, validateFields } = this.props

    validateFields([passwordHandle], (errors) => {
      this.setState({ touched: true })

      if (errors != null) {
        this.setState({ validateTrigger: 'onChange' })
      }
    })
  }

  validateOnChange = () => {
    const { submittedForm } = this.context

    if (submittedForm) {
      this.setState({ validateTrigger: 'onChange' })
    }
  }

  handleOnBlur = (event) => {
    const { value } = event.target
    const { touched } = this.state

    if (value.length > 0 || touched) {
      this.validateOnBlur()
    }
  }

  handleOnChange = (event) => {
    const { score } = this.analyzePassword(event.target.value)

    this.setState({ score })
    this.validateOnChange()
  }

  validatePassword = (_rule, value, callback) => {
    if (value) {
      const { minValidScore } = this.props
      const result = this.analyzePassword(value)
      const valid = result.score >= minValidScore

      if (!valid) {
        const error = (
          <PasswordWarning
            suggestions={result.feedback.suggestions}
            warning={result.feedback.warning}
          />
        )

        callback([error])
      }
    }

    callback()
  }

  render() {
    const {
      name,
      testHandle,
      getFieldDecorator,
      passwordHandle,
      placeholder,
      intl,
      focused,
    } = this.props

    const { score, validateTrigger } = this.state
    const addOnAfter = <PasswordMeter score={score} />

    return (
      <div className={styles.wrapper}>
        <Form.Item
          label={intl.formatMessage(messages.label)}
        >
          {getFieldDecorator(passwordHandle, {
            validateTrigger,
            rules: [
              {
                required: true,
                message: intl.formatMessage(messages.validationMessage),
              },
              {
                validator: this.validatePassword,
              },
            ],
          })(
            <Input
              type="password"
              name={name}
              data-test={testHandle}
              placeholder={placeholder}
              onChange={this.handleOnChange}
              onBlur={this.handleOnBlur}
              addonAfter={addOnAfter}
              autoFocus={focused}
            />,
          )}
        </Form.Item>
      </div>
    )
  }
}

PasswordInput.contextType = FormContext

PasswordInput.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  testHandle: PropTypes.string.isRequired,
  passwordHandle: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  minValidScore: PropTypes.number.isRequired,
  focused: PropTypes.bool,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  validateFields: PropTypes.func.isRequired,
}

PasswordInput.defaultProps = {
  focused: false,
}

export default injectIntl(PasswordInput)
