import { shape, string } from 'prop-types'
import { passwordConfigShape } from 'shapes'

const accountClaimShape = shape({
  formConfig: shape({
    invoiceToken: string.isRequired,
  }),
  user: shape({
    firstName: string,
    lastName: string,
    email: string.isRequired,
    countryOfResidence: string.isRequired,
    agreeTermsAndConditions: string.isRequired,
  }),
  password: passwordConfigShape,
})

export default accountClaimShape
