import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'

import honeybadgerInit from 'honeybadger'

import Flash from 'components/Flash'
import ReCaptcha from 'components/ReCaptcha'
import recaptchaDataShape from 'shapes/recaptchaDataShape'

import FormContext from 'contexts/formContext'
import styles from './styles.module.less'

const honeybadger = honeybadgerInit()

class BaseForm extends React.Component {
  constructor(props) {
    super(props)
    this.recaptcha = React.createRef()
  }

  state = {
    submittedForm: false,
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.setState({ submittedForm: true })
    const { form } = this.props

    form.validateFieldsAndScroll((err) => {
      if (!err) {
        if (this.recaptcha.current) {
          this.refetchRecaptchaTokenAndSubmit()
        } else {
          event.currentTarget.submit()
        }
      }
    })
  }

  refetchRecaptchaTokenAndSubmit = () => {
    const { recaptchaData } = this.props
    this.recaptcha.current.fetchToken(recaptchaData)
      .then((token) => {
        honeybadger.addBreadcrumb('Refetch token', { metadata: { recaptcha_token: token.slice(0, -1) } })
        this.recaptcha.current.assignToken(token)
      })
      .catch((error) => {
        honeybadger.addBreadcrumb('Refetch token error', { metadata: { error } })
        throw error
      })
      .finally(() => {
        document.getElementById('baseForm').submit()
      })
  }

  renderRecaptcha = (recaptchaData) => {
    if (!recaptchaData.ieBrowser) {
      return (
        <ReCaptcha data={recaptchaData} ref={this.recaptcha} />
      )
    }
    return null
  }

  render() {
    const { children, showRequiredMark, recaptchaData } = this.props
    const { formSubmit } = this.context
    const { action, authenticityToken, method } = formSubmit
    const { submittedForm } = this.state

    return (
      <Form
        className={styles.form}
        onSubmit={event => this.handleSubmit(event)}
        action={action}
        acceptCharset="UTF-8"
        method="post"
        hideRequiredMark={!showRequiredMark}
        noValidate
        id="baseForm"
      >
        {method && <input type="hidden" name="_method" value={method} />}
        <input type="hidden" name="authenticity_token" value={authenticityToken} />
        { recaptchaData ? this.renderRecaptcha(recaptchaData) : null}
        <Flash />
        <FormContext.Provider value={{ submittedForm }}>
          {children}
        </FormContext.Provider>
      </Form>
    )
  }
}

BaseForm.contextType = FormContext

BaseForm.propTypes = {
  form: PropTypes.shape({
    validateFieldsAndScroll: PropTypes.func,
  }).isRequired,
  showRequiredMark: PropTypes.bool,
  children: PropTypes.node.isRequired,
  recaptchaData: recaptchaDataShape,
}

BaseForm.defaultProps = {
  showRequiredMark: false,
  recaptchaData: null,
}

export default BaseForm
