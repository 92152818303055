import React from 'react'
import { Button, Form } from 'antd'
import { FormattedMessage } from 'react-intl'

import BaseForm from 'components/BaseForm'
import LegalOptIn from 'components/LegalOptIn'
import CountrySelect from 'components/CountrySelect'

import legalAcceptanceShape from 'shapes/legalAcceptanceShape'
import messages from './messages'

const LegalAcceptanceForm = ({ form, user, consentModalContent }) => {
  const { countryOfResidence, agreeTermsAndConditions } = user
  const { getFieldDecorator } = form

  return (
    <BaseForm form={form}>
      <input name="_method" type="hidden" value="patch" />
      <CountrySelect
        getFieldDecorator={getFieldDecorator}
        name="user[country_of_residence]"
        testHandle="legal_acceptance_country_of_residence"
        initialValue={countryOfResidence}
      />
      <LegalOptIn
        getFieldDecorator={getFieldDecorator}
        name="agree"
        value="yes"
        initialValue={agreeTermsAndConditions}
        testHandle="legal_acceptance_terms_and_conditions"
        consentModalContent={consentModalContent}
      />
      <Button
        type="primary"
        htmlType="submit"
        data-test="legal_acceptance_submit"
      >
        <FormattedMessage {...messages.button} />
      </Button>
    </BaseForm>
  )
}

LegalAcceptanceForm.propTypes = legalAcceptanceShape.isRequired

export default Form.create({ name: 'legal_acceptance' })(LegalAcceptanceForm)
