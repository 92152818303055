import { defineMessages } from 'react-intl'

export default defineMessages({
  contentTitle: {
    id: 'EmailConfirmation.contentTitle',
    defaultMessage: 'Welcome Back!',
  },
  notifyConfirmation: {
    id: 'EmailConfirmation.notifyConfirmation',
    defaultMessage: 'We just sent a confirmation email to {email}.',
  },
  instruction: {
    id: 'EmailConfirmation.instruction',
    defaultMessage: 'Please check your inbox and follow the instructions to verify your email address.',
  },
  resend: {
    id: 'EmailConfirmation.resend',
    defaultMessage: 'Resend email.',
  },
  signInUrl: {
    id: 'EmailConfirmation.signInUrl',
    defaultMessage: 'Return to Sign In',
  },
})
