import { defineMessages } from 'react-intl'

export default defineMessages({
  forgotPassword: {
    id: 'SignIn.forgotPassword',
    defaultMessage: 'Forgot password?',
  },
  footer: {
    id: 'SignIn.footer',
    defaultMessage: 'Don’t have an account?',
  },
  signUpLink: {
    id: 'SignIn.signUpLink',
    defaultMessage: 'Sign up.',
  },
  signedInTitle: {
    id: 'SignIn.signedInTitle',
    defaultMessage: 'Nice To See You',
  },
  title: {
    id: 'SignIn.title',
    defaultMessage: 'Sign In',
  },
})
