import { shape, string } from 'prop-types'

const signInShape = shape({
  links: shape({
    forgotPassword: string.isRequired,
    signUp: string.isRequired,
    partnerSignIn: string.isRequired,
  }).isRequired,
  user: shape({
    email: string.isRequired,
    sessionEmail: string,
  }),
})

export default signInShape
