import { defineMessages } from 'react-intl'

export default defineMessages({
  contentTitle: {
    id: 'ResendEmailConfirmation.contentTitle',
    defaultMessage: "We've Resent Your Confirmation Email",
  },
  checkInbox: {
    id: 'ResendEmailConfirmation.checkInbox',
    defaultMessage: 'Please check your inbox at',
  },
  checkInstructions: {
    id: 'ResendEmailConfirmation.checkInstructions',
    defaultMessage: 'and follow the instructions to verify your email address.',
  },
  reminder: {
    id: 'ResendEmailConfirmation.reminder',
    defaultMessage: 'The email may take a few minutes to arrive. Be sure to check your spam folder.',
  },
  resendUrl: {
    id: 'ResendEmailConfirmation.resendUrl',
    defaultMessage: 'Resend email.',
  },
  button: {
    id: 'ResendEmailConfirmation.button',
    defaultMessage: 'Return to Sign In',
  },
})
