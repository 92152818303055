import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'antd'
import { Heading } from '@generalassembly/cog'
import { FormattedMessage } from 'react-intl'

import styles from './styles.module.less'
import messages from './messages'

const PasswordWarning = ({ suggestions = [], warning }) => {
  const content = (
    <Fragment>
      <Heading type="paragraph-header" margin="stack-xs"><FormattedMessage {...messages.suggestion} /></Heading>
      <ul className={styles.warningList}>
        {suggestions.map(s => <li key={s}>{s}</li>)}
      </ul>
    </Fragment>
  )

  return (
    <Fragment>
      {warning && `${warning}.`}
      {suggestions && suggestions.length > 0 && (
        <Fragment>
          {warning && ' '}
          <FormattedMessage {...messages.try} />
          {' '}
          <Popover placement="bottom" content={content}>
            <span className={styles.complexMessage}><FormattedMessage {...messages.complex} /></span>
            .
          </Popover>
        </Fragment>
      )}
    </Fragment>
  )
}

PasswordWarning.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  warning: PropTypes.string,
}

PasswordWarning.defaultProps = {
  suggestions: [],
  warning: '',
}

export default PasswordWarning
