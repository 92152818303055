import { defineMessages } from 'react-intl'

export default defineMessages({
  passwordPlaceholder: {
    id: 'SignUpForm.passwordPlaceholder',
    defaultMessage: 'Password',
  },
  passwordConfirmationPlaceholder: {
    id: 'SignUpForm.passwordConfirmationPlaceholder',
    defaultMessage: 'Confirm',
  },
  footer: {
    id: 'SignUpForm.footer',
    defaultMessage: 'Already have an account?',
  },
  signInLink: {
    id: 'SignUpForm.signInLink',
    defaultMessage: 'Sign in',
  },
  button: {
    id: 'SignUpForm.button',
    defaultMessage: 'Sign Up',
  },
})
