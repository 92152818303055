import React from 'react'
import PropTypes from 'prop-types'
import { Form, Select, Input } from 'antd'
import { injectIntl } from 'react-intl'

import supportedCountries from 'data/supportedCountries'

import messages from './messages'

class CountrySelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = { selectedCountryCode: props.initialValue }
  }

  handleCountryChange = countryCode => this.setState({ selectedCountryCode: countryCode })

  getFeaturedCountries = (featuredCountryCodes) => {
    const { intl } = this.props
    const countries = []

    featuredCountryCodes.forEach(shortCode => countries.push(
      ...supportedCountries(intl.locale).filter(
        country => country.shortCode === shortCode,
      ),
    ))
    return countries
  }

  getNonFeaturedCountries = (featuredCountries) => {
    const { intl } = this.props
    const featuredCountryCodes = featuredCountries.map(country => country.shortCode)

    return (
      supportedCountries(intl.locale).filter(
        country => !featuredCountryCodes.includes(country.shortCode),
      )
    )
  }

  optGroup = (label, countries) => {
    const { Option, OptGroup } = Select

    return (
      <OptGroup label={label}>
        {countries.map(country => (
          <Option key={country.shortCode}>{country.name}</Option>
        ))}
      </OptGroup>
    )
  }

  filterOption = (input, option) => {
    if (option.type.isSelectOption) {
      try {
        const regex = new RegExp(`^${input}`, 'i')
        return regex.test(option.props.children)
      } catch {
        return false
      }
    }
    return false
  }

  render() {
    const featuredCountryCodes = ['US', 'GB', 'AU', 'CA', 'HK', 'SG', 'TH']
    const featuredCountries = this.getFeaturedCountries(featuredCountryCodes)
    const {
      testHandle, getFieldDecorator, name, intl,
    } = this.props
    const { selectedCountryCode } = this.state

    return (
      <Form.Item label={intl.formatMessage(messages.label)}>
        <Input
          name={name}
          type="hidden"
          value={selectedCountryCode}
          data-test={testHandle}
        />

        {getFieldDecorator('country', {
          initialValue: selectedCountryCode,
          validateTrigger: 'onChange',
          rules: [
            {
              required: true,
              message: intl.formatMessage(messages.validationMessage),
            },
          ],
        })(
          <Select
            showSearch
            filterOption={(input, option) => this.filterOption(input, option)}
            name={name}
            data-test={testHandle}
            onChange={this.handleCountryChange}
          >
            {this.optGroup(
              intl.formatMessage(messages.placeholder),
              featuredCountries,
            )}
            {this.optGroup(
              '---------------',
              this.getNonFeaturedCountries(featuredCountries),
            )}
          </Select>,
        )}
      </Form.Item>
    )
  }
}

CountrySelect.propTypes = {
  testHandle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  intl: PropTypes.shape({ formatMessage: PropTypes.func, locale: PropTypes.string }).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
}

CountrySelect.defaultProps = {
  initialValue: '',
}

export default injectIntl(CountrySelect)
