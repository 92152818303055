import { shape, string } from 'prop-types'

const partnerSignInShape = shape({
  links: shape({
    signIn: string.isRequired,
  }).isRequired,
  user: shape({
    email: string.isRequired,
    rememberMe: string.isRequired,
  }),
})

export default partnerSignInShape
