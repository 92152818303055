import React from 'react'
import { Box, Paragraph } from '@generalassembly/cog'
import { injectIntl, FormattedMessage } from 'react-intl'

import Page from 'components/Page'
import Content from 'components/Content'
import FormContext from 'contexts/formContext'
import PasswordResetForm from 'forms/PasswordResetForm'

import passwordResetShape from 'shapes/passwordResetShape'
import messages from './messages'

const PasswordReset = ({
  formSubmit, flash, links, user, recaptchaData, intl,
}) => (
  <Page>
    <Content title={intl.formatMessage(messages.contentTitle)}>
      <Paragraph margin="stack-xs">
        <FormattedMessage {...messages.title} />
      </Paragraph>
      <Box margin="stack-sm">
        <FormContext.Provider value={{ formSubmit, flash }}>
          <PasswordResetForm links={links} user={user} recaptchaData={recaptchaData} />
        </FormContext.Provider>
      </Box>
    </Content>
  </Page>
)

PasswordReset.propTypes = passwordResetShape.isRequired

export default injectIntl(PasswordReset)
