import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@generalassembly/cog'
import { injectIntl } from 'react-intl'

import Page from 'components/Page'
import Content from 'components/Content'
import FormContext from 'contexts/formContext'
import IdentifyForm from 'forms/IdentifyForm'

import recaptchaDataShape from 'shapes/recaptchaDataShape'
import messages from './messages'

const Identify = ({
  formSubmit, flash, links, recaptchaData, intl, consentModalContent,
}) => (
  <Page>
    <Content title={intl.formatMessage(messages.title)}>
      <Box margin="stack-sm">
        <FormContext.Provider value={{ formSubmit, flash }}>
          <IdentifyForm recaptchaData={recaptchaData} links={links} intl={intl} consentModalContent={consentModalContent} />
        </FormContext.Provider>
      </Box>
    </Content>
  </Page>
)

Identify.propTypes = {
  formSubmit: PropTypes.shape({
    authenticityToken: PropTypes.string,
    action: PropTypes.string,
  }).isRequired,
  flash: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  links: PropTypes.shape({
    signUp: PropTypes.string,
  }).isRequired,
  recaptchaData: recaptchaDataShape.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  consentModalContent: PropTypes.shape({
    privacyPolicy: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
    termsOfService: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
  }).isRequired,
}

export default injectIntl(Identify)
