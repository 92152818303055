import { shape, string, bool } from 'prop-types'
import { requiredDocumentsShape } from 'shapes'

const legalAcceptanceShape = shape({
  formConfig: shape({
    legalOptIn: bool.isRequired,
    requiredDocuments: requiredDocumentsShape,
  }),
  user: shape({
    countryOfResidence: string.isRequired,
    agreeTermsAndConditions: string.isRequired,
  }),
})

export default legalAcceptanceShape
