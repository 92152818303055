import { defineMessages } from 'react-intl'

export default defineMessages({
  contentTitle: {
    id: 'AccountClaim.contentTitle',
    defaultMessage: 'Complete Your Account',
  },
  title: {
    id: 'AccountClaim.title',
    defaultMessage: 'Set a password and select your location for your account linked with {email}.',
  },
})
