import React from 'react'
import { Row, Col } from 'antd'
import { Heading, Paragraph } from '@generalassembly/cog'
import { FormattedMessage } from 'react-intl'

import ErrorLayout from 'components/ErrorLayout'

import styles from './styles.module.less'
import messages from './messages'

const ErrorPage = () => (
  <ErrorLayout>
    <Row>
      <Col sm={24} md={12} className={styles.errorLeft} />
      <Col sm={24} md={12} className={styles.errorRight}>
        <div className={styles.errorContent}>
          <Heading className={styles.errorTitle}>
            <FormattedMessage {...messages.title} />
          </Heading>
          <Paragraph size="large" margin="stack-sm">
            <FormattedMessage {...messages.description} />
          </Paragraph>
          <p>
            <a href="/"><FormattedMessage {...messages.returnUrl} /></a>
          </p>
        </div>
      </Col>
    </Row>
  </ErrorLayout>
)

export default ErrorPage
