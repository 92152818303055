import React from 'react'
import {
  Button, Col, Form, Row,
} from 'antd'
import { Paragraph } from '@generalassembly/cog'
import { FormattedMessage } from 'react-intl'

import BaseForm from 'components/BaseForm'
import EmailInput from 'components/EmailInput'

import passwordResetShape from 'shapes/passwordResetShape'
import messages from './messages'

const PasswordResetForm = ({
  form, links, user, recaptchaData,
}) => {
  const { email } = user
  const { getFieldDecorator, validateFields } = form

  return (
    <BaseForm form={form} recaptchaData={recaptchaData}>
      <EmailInput
        getFieldDecorator={getFieldDecorator}
        validateFields={validateFields}
        testHandle="password_reset_email"
        name="user[email]"
        initialValue={email}
        autoFocus
      />
      <Row align="middle" type="flex">
        <Col xs={24} sm={12}>
          <Paragraph size="small" margin="vertical-sm" color="grey-500">
            <a href={links.signIn}>
              <FormattedMessage {...messages.signInUrl} />
            </a>
          </Paragraph>
        </Col>
        <Col sm={12} className="u-text-right">
          <Button
            type="primary"
            htmlType="submit"
            data-test="password_reset_submit"
          >
            <FormattedMessage {...messages.button} />
          </Button>
        </Col>
      </Row>
    </BaseForm>
  )
}

PasswordResetForm.propTypes = passwordResetShape.isRequired

export default Form.create({ name: 'password_reset' })(PasswordResetForm)
