import { defineMessages } from 'react-intl'

export default defineMessages({
  label: {
    id: 'EmailInput.label',
    defaultMessage: 'Email',
  },
  placeholder: {
    id: 'EmailInput.placeholder',
    defaultMessage: 'Your Email',
  },
  validationEmail: {
    id: 'EmailInput.validationEmail',
    defaultMessage: 'Please enter a valid email address',
  },
  validationBlankEmail: {
    id: 'EmailInput.validationBlankEmail',
    defaultMessage: "Email can't be blank",
  },
})
