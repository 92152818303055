import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'
import { injectIntl } from 'react-intl'

import styles from './styles.module.less'
import messages from './messages'

class PasswordConfirmationInput extends React.Component {
  validatePasswordConfirmation = (_rule, value, callback) => {
    const { form, passwordHandle, intl } = this.props
    if (value && value !== form.getFieldValue(passwordHandle)) {
      callback(intl.formatMessage(messages.validationPasswordMatchMessage))
    } else {
      callback()
    }
  }

  isPasswordConfirmDisabled = () => {
    const { form, passwordHandle } = this.props

    const err = form.getFieldError(passwordHandle)
    const value = form.getFieldValue(passwordHandle)

    return !!err || !value
  }

  render() {
    const {
      getFieldDecorator, name, testHandle, placeholder, intl,
    } = this.props

    return (
      <div className={styles.wrapper}>
        <Form.Item label={intl.formatMessage(messages.label)}>
          {getFieldDecorator('confirm', {
            rules: [
              {
                required: true,
                message: intl.formatMessage(messages.validationMessage),
              },
              {
                validator: this.validatePasswordConfirmation,
              },
            ],
          })(
            <Input
              type="password"
              name={name}
              data-test={testHandle}
              placeholder={placeholder}
              disabled={this.isPasswordConfirmDisabled()}
            />,
          )}
        </Form.Item>
      </div>
    )
  }
}

PasswordConfirmationInput.propTypes = {
  name: PropTypes.string.isRequired,
  testHandle: PropTypes.string.isRequired,
  passwordHandle: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
    getFieldError: PropTypes.func,
  }).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
}

export default injectIntl(PasswordConfirmationInput)
