import React from 'react'
import { Box } from '@generalassembly/cog'
import { injectIntl } from 'react-intl'

import Page from 'components/Page'
import Content from 'components/Content'
import FormContext from 'contexts/formContext'
import SignUpForm from 'forms/SignUpForm'

import signUpShape from 'shapes/signUpShape'
import messages from './messages'

class SignUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      formSubmit, flash, links, user, password, consentModalContent, recaptchaData, intl,
    } = this.props
    return (
      <Page>
        <Content title={intl.formatMessage(messages.title)} size="lg">
          <Box margin="stack-sm">
            <FormContext.Provider value={{ formSubmit, flash }}>
              <SignUpForm user={user} password={password} links={links} consentModalContent={consentModalContent} recaptchaData={recaptchaData} />
            </FormContext.Provider>
          </Box>
        </Content>
      </Page>
    )
  }
}

SignUp.propTypes = signUpShape.isRequired

export default injectIntl(SignUp)
