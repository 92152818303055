import React, { Fragment } from 'react'
import { Alert } from 'antd'
import { Box } from '@generalassembly/cog'

const BrowserWarning = () => {
  const message = (
    <Fragment>
      You are using an unsupported or outdated browser. Please
      {' '}
      <a href="http://outdatedbrowser.com" target="_blank" rel="noreferrer noopener">
        upgrade your browser
      </a>
      {' '}
      to improve your experience.
      <br />
      We recommend using the latest Chrome browser for the best experience.
    </Fragment>
  )

  return (
    <Fragment>
      <Box margin="vertical-sm">
        <Alert
          iconType="ga-warning-circle"
          message={message}
          showIcon
          type="warning"
        />
      </Box>
    </Fragment>
  )
}

export default BrowserWarning
