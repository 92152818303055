import { defineMessages } from 'react-intl'

export default defineMessages({
  signInUrl: {
    id: 'PasswordResetForm.signInUrl',
    defaultMessage: 'Return to sign in.',
  },
  button: {
    id: 'PasswordResetForm.button',
    defaultMessage: 'Request Reset Link',
  },
})
