import { shape, string } from 'prop-types'

const passwordResetShape = shape({
  links: shape({
    signIn: string.isRequired,
  }),
  user: shape({
    email: string.isRequired,
  }),
})

export default passwordResetShape
