import React from 'react'
import {
  Form, Button, Col, Row,
} from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'

import BaseForm from 'components/BaseForm'
import PasswordInput from 'components/PasswordInput'
import PasswordConfirmationInput from 'components/PasswordConfirmationInput'
import LegalOptIn from 'components/LegalOptIn'
import CountrySelect from 'components/CountrySelect'
import FullNameInput from 'components/FullNameInput'

import accountClaimShape from 'shapes/accountClaimShape'
import messages from './messages'
import styles from './styles.module.less'

const AccountClaimForm = ({
  form, user, password, formConfig, consentModalContent, intl,
}) => {
  const {
    firstName, lastName, email, countryOfResidence, agreeTermsAndConditions,
  } = user
  const { invoiceToken } = formConfig
  const { getFieldDecorator, validateFields } = form
  const passwordHandle = 'password'
  const testAttributes = {
    firstName: 'account_claim_first_name',
    lastName: 'account_claim_last_name',
  }

  return (
    <BaseForm form={form} showRequiredMark>
      <Row gutter={32}>
        <Col md={12}>
          <PasswordInput
            name="user[password]"
            testHandle="account_claim_password"
            getFieldDecorator={getFieldDecorator}
            validateFields={validateFields}
            passwordHandle={passwordHandle}
            placeholder={intl.formatMessage(messages.passwordPlaceholder)}
            minValidScore={password.minScore}
            focused
          />
        </Col>
        <Col md={12}>
          <PasswordConfirmationInput
            name="user[password_confirmation]"
            testHandle="account_claim_password_confirmation"
            getFieldDecorator={getFieldDecorator}
            passwordHandle={passwordHandle}
            placeholder={intl.formatMessage(messages.passwordConfirmationPlaceholder)}
            form={form}
          />
        </Col>
      </Row>
      <CountrySelect
        getFieldDecorator={getFieldDecorator}
        name="user[country_of_residence]"
        testHandle="account_claim_country_of_residence"
        initialValue={countryOfResidence}
      />
      <Row gutter={32}>
        <FullNameInput
          intl={intl}
          firstName={firstName}
          getFieldDecorator={getFieldDecorator}
          lastName={lastName}
          autoFocus={false}
          testAttributes={testAttributes}
        />
      </Row>
      <LegalOptIn
        getFieldDecorator={getFieldDecorator}
        name="agree"
        value="yes"
        initialValue={agreeTermsAndConditions}
        testHandle="account_claim_terms_and_conditions"
        consentModalContent={consentModalContent}
      />
      <input
        type="hidden"
        name="user[invoice_token]"
        value={invoiceToken}
      />
      <input
        type="hidden"
        name="user[email]"
        value={email}
      />
      <div className={styles.buttonContainer}>
        <Button
          type="primary"
          htmlType="submit"
          data-test="account_claim_submit"
          className={styles.button}
        >
          <FormattedMessage {...messages.button} />
        </Button>
      </div>
    </BaseForm>
  )
}

AccountClaimForm.propTypes = accountClaimShape.isRequired

export default Form.create({ name: 'account_claim' })(injectIntl(AccountClaimForm))
