import React from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line import/no-extraneous-dependencies
import honeybadgerInit from 'honeybadger'
import ErrorBoundary from '@honeybadger-io/react'
import 'antd/dist/antd.less'
// eslint-disable-next-line import/no-unresolved
import 'cog/index.less'

import ErrorPage from 'components/ErrorPage'
import styles from './styles.module.less'

const honeybadger = honeybadgerInit()

const Page = ({ children }) => (
  <ErrorBoundary honeybadger={honeybadger} ErrorComponent={ErrorPage}>
    <div className={styles.page}>{children}</div>
  </ErrorBoundary>
)

Page.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Page
