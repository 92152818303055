import React from 'react'
import {
  Button, Col, Checkbox, Form, Input, Row,
} from 'antd'
import { Box, Paragraph } from '@generalassembly/cog'
import { injectIntl, FormattedMessage } from 'react-intl'

import BaseForm from 'components/BaseForm'
import EmailInput from 'components/EmailInput'

import signInShape from 'shapes/signInShape'
import styles from './styles.module.less'
import messages from './messages'

class SignInForm extends React.Component {
  renderRememberMe() {
    const { form } = this.props
    const { getFieldDecorator } = form

    return (
      <div className={styles.rememberMeWrapper}>
        <Form.Item>
          <Row align="middle" type="flex">
            <Col sm={16}>
              {getFieldDecorator('rememberMe', {
                valuePropName: 'checked',
              })(
                <Checkbox
                  name="user[remember_me]"
                  value="1"
                  defaultChecked={false}
                  data-test="sign_in_remember_me"
                >
                  <FormattedMessage {...messages.remember} />
                </Checkbox>,
              )}
            </Col>
            <Col sm={8} className="u-text-right">
              <Button
                block
                type="primary"
                htmlType="submit"
                data-test="sign_in_submit"
              >
                <FormattedMessage {...messages.button} />
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </div>
    )
  }

  renderPasswordInputCustomLabel() {
    const { links } = this.props

    return (
      <Box>
        <div className="u-float-left">
          <FormattedMessage {...messages.passwordLabel} />
        </div>
        <div className="u-float-right">
          <a href={links.forgotPassword} className={styles.rightLabel}>
            <FormattedMessage {...messages.forgotPassword} />
          </a>
        </div>
      </Box>
    )
  }

  render() {
    const {
      form, links, user, intl, recaptchaData,
    } = this.props
    const {
      email, sessionEmail,
    } = user
    const { getFieldDecorator, validateFields } = form

    return (
      <BaseForm form={form} recaptchaData={recaptchaData}>
        {sessionEmail ? (
          <Paragraph margin="stack-sm">
            <input type="hidden" name="user[email]" value={sessionEmail} />
            {sessionEmail}
            {'  '}
            <a data-test="sign_in_clear_identifier" href={links.clearIdentifier}>
              <FormattedMessage {...messages.switch} />
            </a>
          </Paragraph>
        ) : (
          <EmailInput
            getFieldDecorator={getFieldDecorator}
            validateFields={validateFields}
            testHandle="sign_in_email"
            name="user[email]"
            initialValue={email}
            autoFocus
          />
        )}
        <Form.Item label={this.renderPasswordInputCustomLabel()} className={styles.customFormItemLabel}>
          {getFieldDecorator('password', {
            validateTrigger: 'onChange',
            rules: [
              {
                required: true,
                message: intl.formatMessage(messages.passwordValidator),
              },
            ],
          })(
            <Input.Password
              name="user[password]"
              type="password"
              placeholder={intl.formatMessage(messages.passwordPlaceholder)}
              data-test="sign_in_password"
              autoFocus={!!sessionEmail}
            />,
          )}
        </Form.Item>
        { this.renderRememberMe() }
      </BaseForm>
    )
  }
}

SignInForm.propTypes = signInShape.isRequired

export default Form.create({ name: 'sign_in' })(injectIntl(SignInForm))
