import React from 'react'
import { Box, Paragraph } from '@generalassembly/cog'
import { injectIntl, FormattedMessage } from 'react-intl'

import Page from 'components/Page'
import Content from 'components/Content'
import FormContext from 'contexts/formContext'
import PartnerSignInForm from 'forms/PartnerSignInForm'

import partnerSignInShape from 'shapes/passwordResetShape'
import messages from './messages'

const PartnerSignIn = ({
  formSubmit, flash, links, email, intl,
}) => (
  <Page>
    <Content title={intl.formatMessage(messages.contentTitle)}>
      <Box margin="stack-sm">
        <FormContext.Provider value={{ formSubmit, flash }}>
          <PartnerSignInForm email={email} />
        </FormContext.Provider>
      </Box>
      <Paragraph size="small">
        <FormattedMessage {...messages.team} />
        {' '}
        <a data-test="partner_sign_in_sign_in" href={links.signIn}>
          <FormattedMessage {...messages.signInUrl} />
        </a>
      </Paragraph>
    </Content>
  </Page>
)

PartnerSignIn.propTypes = partnerSignInShape.isRequired

export default injectIntl(PartnerSignIn)
