import { defineMessages } from 'react-intl'

export default defineMessages({
  button: {
    id: 'PasswordChangeForm.button',
    defaultMessage: 'Change Password',
  },
  passwordPlaceholder: {
    id: 'PasswordChangeForm.passwordPlaceholder',
    defaultMessage: 'Password',
  },
  passwordConfirmationPlaceholder: {
    id: 'PasswordChangeForm.passwordConfirmationPlaceholder',
    defaultMessage: 'Confirm',
  },
})
