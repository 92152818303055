import React from 'react'
import {
  Form, Input, Col,
} from 'antd'
import { injectIntl } from 'react-intl'


import PropTypes from 'prop-types'
import messages from './messages'

const FullNameInput = ({
  intl, firstName, lastName, autoFocus, testAttributes, getFieldDecorator,
}) => {
  const eastAsianLocales = (process.env.EAST_ASIAN_LOCALES || '').split(',')
  const { locale } = intl
  const isEastAsianLocale = eastAsianLocales.includes(locale)
  const lastNameFocus = autoFocus && isEastAsianLocale
  const firstNameFocus = autoFocus && !isEastAsianLocale
  const lastNameInput = <LastNameInput key="lastNameInput" intl={intl} lastName={lastName} autoFocus={lastNameFocus} dataTest={testAttributes.lastName} getFieldDecorator={getFieldDecorator} />
  const firstNameInput = <FirstNameInput key="firstNameInput" intl={intl} firstName={firstName} autoFocus={firstNameFocus} dataTest={testAttributes.firstName} getFieldDecorator={getFieldDecorator} />
  return (
    <>
      { isEastAsianLocale ? ([lastNameInput, firstNameInput]) : ([firstNameInput, lastNameInput]) }
    </>
  )
}

const FirstNameInput = ({
  intl, firstName, autoFocus, dataTest, getFieldDecorator,
}) => (
  <Col md={12}>
    <Form.Item label={intl.formatMessage(messages.firstNameLabel)}>
      {getFieldDecorator('user[first_name]', {
        initialValue: firstName,
        rules: [{
          required: true,
          message: intl.formatMessage(messages.firstNameValidationMessage),
        }],
      })(
        <Input
          name="user[first_name]"
          placeholder={intl.formatMessage(messages.firstNamePlaceholder)}
          data-test={dataTest}
          autoFocus={autoFocus}
        />,
      )}
    </Form.Item>
  </Col>
)

const LastNameInput = ({
  intl, lastName, autoFocus, dataTest, getFieldDecorator,
}) => (
  <Col md={12}>
    <Form.Item label={intl.formatMessage(messages.lastNameLabel)}>
      {getFieldDecorator('user[last_name]', {
        initialValue: lastName,
        rules: [{
          required: true,
          message: intl.formatMessage(messages.lastNameValidationMessage),
        }],
      })(
        <Input
          name="user[last_name]"
          placeholder={intl.formatMessage(messages.lastNamePlaceholder)}
          data-test={dataTest}
          autoFocus={autoFocus}
        />,
      )}
    </Form.Item>
  </Col>
)

FullNameInput.propTypes = {
  intl: PropTypes.shape({ formatMessage: PropTypes.func, locale: PropTypes.string }).isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool.isRequired,
  testAttributes: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
}

FirstNameInput.propTypes = {
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  firstName: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool.isRequired,
  dataTest: PropTypes.string.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
}

LastNameInput.propTypes = {
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  lastName: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool.isRequired,
  dataTest: PropTypes.string.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
}

export default injectIntl(FullNameInput)
