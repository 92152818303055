import React from 'react'
import honeybadgerInit from 'honeybadger'
import recaptchaDataShape from 'shapes/recaptchaDataShape'

const honeybadger = honeybadgerInit()

class ReCaptcha extends React.Component {
  constructor(props) {
    super(props)
    this.tokenField = React.createRef()
  }

  state = {
    token: '',
  }

  componentDidMount() {
    const { data } = this.props
    this.fetchToken(data)
      .then(token => this.setState({ token }))
      .catch(error => honeybadger.addBreadcrumb('Fetch token error', { metadata: { error } }))
  }

  fetchToken = (recaptchaData) => {
    const promise = new Promise((resolve, reject) => {
      if (window.grecaptcha) {
        // eslint-disable-next-line no-undef
        grecaptcha.ready(() => {
          const { siteKey, action } = recaptchaData
          // eslint-disable-next-line no-undef
          grecaptcha.execute(siteKey, { action })
            .then((token) => {
              resolve(token)
            })
        })
      } else {
        reject(new Error('window.grecaptcha is not loaded'))
      }
    })
    return promise
  }

  assignToken = (token) => {
    this.tokenField.current.value = token
  }

  render() {
    const { token } = this.state
    const { data: { action, inputUuid } } = this.props

    return (
      <React.Fragment>
        <input name="recaptcha_action" type="hidden" value={action} />
        <input
          name="recaptcha_token"
          type="hidden"
          id={`recaptcha_token_${inputUuid}`}
          value={token}
          ref={this.tokenField}
        />
      </React.Fragment>
    )
  }
}

ReCaptcha.propTypes = {
  data: recaptchaDataShape.isRequired,
}

export default ReCaptcha
