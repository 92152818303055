import React from 'react'
import { Box, Paragraph } from '@generalassembly/cog'
import { injectIntl, FormattedMessage } from 'react-intl'
import Page from 'components/Page'
import Content from 'components/Content'
import FormContext from 'contexts/formContext'
import SignInForm from 'forms/SignInForm'

import signInShape from 'shapes/signInShape'
import messages from './messages'

class SignIn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      formSubmit, flash, links, user, recaptchaData, intl,
    } = this.props
    const { sessionEmail } = user
    const title = sessionEmail ? intl.formatMessage(messages.signedInTitle) : intl.formatMessage(messages.title)

    return (
      <Page>
        <Content title={title}>
          <Box margin="stack-sm">
            <FormContext.Provider value={{ formSubmit, flash }}>
              <SignInForm user={user} links={links} recaptchaData={recaptchaData} />
            </FormContext.Provider>
          </Box>
          <Paragraph size="small" margin="stack-xs">
            <FormattedMessage {...messages.footer} />
            {' '}
            <a data-test="sign_in_sign_up" href={links.signUp}>
              <FormattedMessage {...messages.signUpLink} />
            </a>
          </Paragraph>
        </Content>
      </Page>
    )
  }
}

SignIn.propTypes = signInShape.isRequired

export default injectIntl(SignIn)
