import React from 'react'
import {
  Form, Button, Col, Row,
} from 'antd'
import { Paragraph } from '@generalassembly/cog'
import { injectIntl, FormattedMessage } from 'react-intl'

import BaseForm from 'components/BaseForm'
import EmailInput from 'components/EmailInput'
import PasswordInput from 'components/PasswordInput'
import PasswordConfirmationInput from 'components/PasswordConfirmationInput'
import LegalOptIn from 'components/LegalOptIn'
import CountrySelect from 'components/CountrySelect'
import FullNameInput from 'components/FullNameInput'

import signUpShape from 'shapes/signUpShape'
import styles from './styles.module.less'
import messages from './messages'

const SignUpForm = ({
  form, user, password, links, consentModalContent, recaptchaData, intl,
}) => {
  const {
    firstName, lastName, email, countryOfResidence, termsAndConditions,
  } = user
  const { getFieldDecorator, validateFields } = form
  const passwordHandle = 'password'
  const testAttributes = {
    firstName: 'sign_up_first_name',
    lastName: 'sign_up_last_name',
  }

  return (
    <BaseForm form={form} showRequiredMark recaptchaData={recaptchaData}>
      <Row gutter={32}>
        <FullNameInput
          intl={intl}
          firstName={firstName}
          lastName={lastName}
          autoFocus
          testAttributes={testAttributes}
          getFieldDecorator={getFieldDecorator}
        />
      </Row>
      <EmailInput
        getFieldDecorator={getFieldDecorator}
        validateFields={validateFields}
        testHandle="sign_up_email"
        name="user[email]"
        initialValue={email}
      />
      <Row gutter={32}>
        <Col md={12}>
          <PasswordInput
            name="user[password]"
            testHandle="sign_up_password"
            getFieldDecorator={getFieldDecorator}
            validateFields={validateFields}
            passwordHandle={passwordHandle}
            placeholder={intl.formatMessage(messages.passwordPlaceholder)}
            minValidScore={password.minScore}
          />
        </Col>
        <Col md={12}>
          <PasswordConfirmationInput
            name="user[password_confirmation]"
            testHandle="sign_up_password_confirmation"
            getFieldDecorator={getFieldDecorator}
            passwordHandle={passwordHandle}
            placeholder={intl.formatMessage(messages.passwordConfirmationPlaceholder)}
            form={form}
          />
        </Col>
      </Row>

      <CountrySelect
        getFieldDecorator={getFieldDecorator}
        name="user[country_of_residence]"
        testHandle="sign_up_country_of_residence"
        initialValue={countryOfResidence}
      />
      <LegalOptIn
        getFieldDecorator={getFieldDecorator}
        name="user[terms_and_conditions]"
        testHandle="sign_up_terms_and_conditions"
        initialValue={termsAndConditions}
        consentModalContent={consentModalContent}
      />

      <Row type="flex" className={styles.existingUserSignIn}>
        <Col xs={{ span: 24, order: 2 }} md={12}>
          <Paragraph size="small" padding="vertical-xs">
            <FormattedMessage {...messages.footer} />
            {' '}
            {' '}
            <a data-test="sign_up_sign_in" href={links.signIn}>
              <FormattedMessage {...messages.signInLink} />
            </a>
          </Paragraph>
        </Col>
        <Col xs={{ span: 24, order: 1 }} md={{ span: 5, offset: 7, order: 2 }} lg={{ span: 4, offset: 8, order: 2 }}>
          <Button
            block
            type="primary"
            htmlType="submit"
            data-test="sign_up_submit"
            className={styles.submit}
          >
            <FormattedMessage {...messages.button} />
          </Button>
        </Col>
      </Row>
    </BaseForm>
  )
}

SignUpForm.propTypes = signUpShape.isRequired

export default Form.create({ name: 'sign_up' })(injectIntl(SignUpForm))
