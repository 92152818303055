import React from 'react'
import { Box, Paragraph } from '@generalassembly/cog'
import { injectIntl, FormattedMessage } from 'react-intl'

import Page from 'components/Page'
import Content from 'components/Content'
import FormContext from 'contexts/formContext'
import AccountClaimForm from 'forms/AccountClaimForm'

import accountClaimShape from 'shapes/accountClaimShape'
import messages from './messages'

const AccountClaim = ({
  formSubmit, flash, user, password, formConfig, consentModalContent, intl,
}) => (
  <Page>
    <Content title={intl.formatMessage(messages.contentTitle)} size="lg">
      <Paragraph margin="stack-sm">
        <FormattedMessage
          {...messages.title}
          values={{
            email: <b>{user.email}</b>,
          }}
        />
      </Paragraph>
      <Box margin="stack-sm">
        <FormContext.Provider value={{ formSubmit, flash }}>
          <AccountClaimForm user={user} password={password} formConfig={formConfig} consentModalContent={consentModalContent} />
        </FormContext.Provider>
      </Box>
    </Content>
  </Page>
)

AccountClaim.propTypes = accountClaimShape.isRequired

export default injectIntl(AccountClaim)
