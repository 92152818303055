import { defineMessages } from 'react-intl'

export default defineMessages({
  contentTitle: {
    id: 'PartnerSignIn.contentTitle',
    defaultMessage: 'Partner Sign In',
  },
  team: {
    id: 'PartnerSignIn.team',
    defaultMessage: 'Not part of a team?',
  },
  signInUrl: {
    id: 'PartnerSignIn.signInUrl',
    defaultMessage: 'Sign in here.',
  },
})
