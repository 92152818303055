import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'ErrorPage.title',
    defaultMessage: 'Application Error',
  },
  description: {
    id: 'ErrorPage.description',
    defaultMessage: "We're sorry, but something went wrong.",
  },
  returnUrl: {
    id: 'ErrorPage.returnUrl',
    defaultMessage: 'Return to homepage',
  },
})
