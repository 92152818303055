import React from 'react'
import PropTypes from 'prop-types'
import { Paragraph } from '@generalassembly/cog'
import { injectIntl, FormattedMessage } from 'react-intl'

import Page from 'components/Page'
import Content from 'components/Content'

import messages from './messages'

const EmailVerification = ({ user, intl }) => (
  <Page>
    <Content title={intl.formatMessage(messages.contentTitle)}>
      <Paragraph margin="stack-md">
        <FormattedMessage
          {...messages.notifyConfirmation}
          values={{
            email: <b>{user.email}</b>,
          }}
        />
      </Paragraph>
      <Paragraph margin="stack-md">
        <FormattedMessage {...messages.reminderContactUs} />
        {' '}
        <a href={`mailto:${intl.formatMessage(messages.helpEmail)}`}>
          <FormattedMessage {...messages.helpEmail} />
          .
        </a>
      </Paragraph>
      <Paragraph margin="stack-md">
        <FormattedMessage {...messages.reminderMarkNotSpan} />
      </Paragraph>
    </Content>
  </Page>
)

EmailVerification.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
}

export default injectIntl(EmailVerification)
