import { defineMessages } from 'react-intl'

export default defineMessages({
  help: {
    id: 'Content.help',
    defaultMessage: 'Need help? Contact us at {email}.',
  },
  helpEmail: {
    id: 'Content.helpEmail',
    defaultMessage: 'help@ga.co',
  },
})
