import React from 'react'
import PropTypes from 'prop-types'
import {
  Row, Col, Button,
} from 'antd'
import { Box, Paragraph } from '@generalassembly/cog'
import { injectIntl, FormattedMessage } from 'react-intl'

import Page from 'components/Page'
import Content from 'components/Content'

import messages from './messages'

const ResendEmailConfirmation = ({ links, user, intl }) => (
  <Page>
    <Content title={intl.formatMessage(messages.contentTitle)}>
      <Paragraph margin="stack-md">
        <FormattedMessage {...messages.checkInbox} />
        {' '}
        <strong>{user.email}</strong>
        {' '}
        <FormattedMessage {...messages.checkInstructions} />
      </Paragraph>

      <Paragraph margin="stack-md">
        <FormattedMessage {...messages.reminder} />
      </Paragraph>
      <Row>
        <Col sm={12}>
          <Box margin="stack-xs">
            <a
              href={links.resendConfirmation}
              data-method="post"
              data-test="resend_email_confirmation_resend"
            >
              <FormattedMessage {...messages.resendUrl} />
            </a>
          </Box>
        </Col>
        <Col sm={12}>
          <Box margin="stack-xs">
            <Button
              block
              type="primary"
              href={links.signIn}
              data-test="resend_email_confirmation_sign_in"
            >
              <FormattedMessage {...messages.button} />
            </Button>
          </Box>
        </Col>
      </Row>
    </Content>
  </Page>
)

ResendEmailConfirmation.propTypes = {
  links: PropTypes.shape({
    resendConfirmation: PropTypes.string.isRequired,
    signIn: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
}

export default injectIntl(ResendEmailConfirmation)
