import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Col, Form, Row, Popover,
} from 'antd'
import { Box, Paragraph } from '@generalassembly/cog'
import { injectIntl, FormattedMessage } from 'react-intl'

import BaseForm from 'components/BaseForm'
import EmailInput from 'components/EmailInput'
import LegalOptInModal from 'components/LegalOptInModal'

import recaptchaDataShape from 'shapes/recaptchaDataShape'
import legalOptInStyles from 'components/LegalOptIn/styles.module.less'
import styles from './styles.module.less'
import messages from './messages'

const IdentifyForm = ({
  form, recaptchaData, links, intl, consentModalContent,
}) => {
  const { getFieldDecorator, validateFields } = form

  const renderPopover = (title, content) => {
    const modal = <LegalOptInModal title={title} content={content} />

    return (
      <Popover
        content={modal}
        trigger="click"
        placement="bottom"
        className={legalOptInStyles.legalPopover}
      >
        <span className={legalOptInStyles.popoverLink}>{title}</span>
      </Popover>
    )
  }

  return (
    <BaseForm form={form} recaptchaData={recaptchaData}>
      <EmailInput
        getFieldDecorator={getFieldDecorator}
        validateFields={validateFields}
        testHandle="identify_email"
        name="email"
        initialValue=""
        autoFocus
      />
      {
        ['en', 'ja', 'it'].includes(intl.locale) && (
          <Paragraph size="small" margin="vertical-xs">
            <FormattedMessage
              {...messages.agree}
              values={{
                privacyPolicy: renderPopover(
                  intl.formatMessage(messages.privacy),
                  consentModalContent.privacyPolicy,
                ),
                termsOfService: renderPopover(
                  intl.formatMessage(messages.terms),
                  consentModalContent.termsOfService,
                ),
              }}
            />
          </Paragraph>
        )
      }
      <div className={styles.rememberMeWrapper}>
        <Form.Item>
          <Row align="middle" type="flex">
            <Col xs={24} sm={16}>
              <Box margin="vertical-sm">
                <FormattedMessage {...messages.footer} />
                {' '}
                <a data-test="identify_create_account" href={links.signUp}>
                  <FormattedMessage {...messages.signUpLink} />
                </a>
              </Box>
            </Col>
            <Col sm={8} className="u-text-right">
              <Button
                block
                type="primary"
                htmlType="submit"
                data-test="identify_submit"
              >
                <FormattedMessage {...messages.button} />
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </div>
    </BaseForm>
  )
}

IdentifyForm.propTypes = {
  recaptchaData: recaptchaDataShape.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
  }).isRequired,
  links: PropTypes.shape({
    signUp: PropTypes.string,
  }).isRequired,
  consentModalContent: PropTypes.shape({
    privacyPolicy: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
    termsOfService: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
  }).isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func, locale: PropTypes.string }).isRequired,
}

export default injectIntl(Form.create({ name: 'identify' })(IdentifyForm))
