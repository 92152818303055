import { shape, string } from 'prop-types'
import { passwordConfigShape } from 'shapes'

const signUpShape = shape({
  links: shape({
    signIn: string.isRequired,
  }).isRequired,
  user: shape({
    firstName: string,
    lastName: string,
    email: string.isRequired,
    countryOfResidence: string.isRequired,
    termsAndConditions: string.isRequired,
  }),
  password: passwordConfigShape.isRequired,
})

export default signUpShape
