import React from 'react'
import { Button } from 'antd'
import { Box, Paragraph } from '@generalassembly/cog'
import { injectIntl, FormattedMessage } from 'react-intl'

import Page from 'components/Page'
import Content from 'components/Content'

import samlConfirmLogoutShape from 'shapes/samlConfirmLogoutShape'
import messages from './messages'

const SamlConfirmLogout = ({ links, partner, intl }) => (
  <Page>
    <Content title={intl.formatMessage(messages.contentTitle)}>
      <Paragraph margin="stack-lg">
        <FormattedMessage {...messages.selectOption} />
      </Paragraph>
      <Box margin="stack-sm">
        <Button
          block
          type="primary"
          href={links.logout}
          data-test="saml_confirm_logout_logout"
        >
          <FormattedMessage {...messages.logout} />
        </Button>
      </Box>
      <Box margin="stack-sm">
        <Button
          block
          href={links.samlLogout}
          data-test="saml_confirm_logout_saml_logout"
        >
          <FormattedMessage {...messages.logoutAll} />
        </Button>
      </Box>
      <Paragraph size="small" margin="stack-xs">
        <FormattedMessage {...messages.logoutAllMessage} />
        {' '}
        {partner.name}
        {' '}
        <FormattedMessage {...messages.logoutAllMessageCont} />
      </Paragraph>
    </Content>
  </Page>
)

SamlConfirmLogout.propTypes = samlConfirmLogoutShape.isRequired

export default injectIntl(SamlConfirmLogout)
