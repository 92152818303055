import React from 'react'
import { Box, Paragraph } from '@generalassembly/cog'
import { injectIntl, FormattedMessage } from 'react-intl'

import Page from 'components/Page'
import Content from 'components/Content'
import FormContext from 'contexts/formContext'
import LegalAcceptanceForm from 'forms/LegalAcceptanceForm'

import legalAcceptanceShape from 'shapes/legalAcceptanceShape'
import messages from './messages'

class LegalAcceptance extends React.Component {
  legalDocumentsToSentence() {
    const { formConfig, intl } = this.props
    const { requiredDocumentsCount, policyNameList } = formConfig

    if (requiredDocumentsCount > 1) {
      return `${intl.formatMessage(messages.other, { our: intl.formatMessage(messages.our), policyNameList })}`
    }
    return `${intl.formatMessage(messages.one, { our: intl.formatMessage(messages.our), policyNameList })}`
  }

  render() {
    const {
      formSubmit, flash, user, consentModalContent,
    } = this.props

    return (
      <Page>
        <Content title={this.legalDocumentsToSentence()}>
          <Box margin="stack-sm">
            <FormContext.Provider value={{ formSubmit, flash }}>
              <Paragraph margin="stack-sm">
                <FormattedMessage {...messages.review} />
              </Paragraph>
              <LegalAcceptanceForm user={user} consentModalContent={consentModalContent} />
            </FormContext.Provider>
          </Box>
        </Content>
      </Page>
    )
  }
}

LegalAcceptance.propTypes = legalAcceptanceShape.isRequired

export default injectIntl(LegalAcceptance)
