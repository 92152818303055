import { shape, string, number } from 'prop-types'

const passwordUpdateShape = shape({
  formConfig: shape({
    resetPasswordToken: string.isRequired,
    passwordMinScore: number.isRequired,
  }),
})

export default passwordUpdateShape
