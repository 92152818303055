import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Paragraph } from '@generalassembly/cog'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Modal } from 'antd'

import messages from './messages'

class PasswordChangeMessage extends React.Component {
  showModal = (intl) => {
    Modal.info({
      title: intl.formatMessage(messages.modalTitle),
      content: (
        <Paragraph size="small" margin="stack">
          {intl.formatMessage(messages.modalRecommend)}
          {' '}
          {intl.formatMessage(messages.modalCombination)}
          {' '}
          {intl.formatMessage(messages.modalExample)}
        </Paragraph>
      ),
      maskClosable: true,
      onOk() {},
    })
  }

  renderMessage = ({ reason, intl }) => {
    switch (reason) {
      case 'invalid':
        return (
          <Fragment>
            <FormattedMessage
              {...messages.createStrongerPasswordWithsecureComplex}
              values={{
                createStrongerPasswordInstruction: (<FormattedMessage {...messages.createStrongerPasswordInstruction} />),
                modalUrl: (<a onClick={() => this.showModal(intl)}><FormattedMessage {...messages.modalUrl} /></a>),
                secureComplex: (<FormattedMessage {...messages.secureComplex} />),
              }}
            />
          </Fragment>
        )
      case 'default':
        return (
          <Fragment>
            <FormattedMessage {...messages.createPasswordInstruction} />
            {' '}
            {/* eslint-disable-next-line */}
            <a onClick={() => this.showModal(intl)}><FormattedMessage {...messages.modalUrl} /></a>
            {' '}
            <FormattedMessage {...messages.secure} />
          </Fragment>
        )
      default:
        return <FormattedMessage {...messages.instruction} />
    }
  }

  render() {
    const { reason, intl } = this.props

    return (
      <Paragraph margin="stack">
        { this.renderMessage({ reason, intl }) }
      </Paragraph>
    )
  }
}

PasswordChangeMessage.propTypes = {
  reason: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
}

export default injectIntl(PasswordChangeMessage)
