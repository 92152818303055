import { shape, PropTypes } from 'prop-types'

const recaptchaDataShape = shape({
  recaptchaData: PropTypes.shape({
    action: PropTypes.string,
    siteKey: PropTypes.string,
    inputUuid: PropTypes.string,
    ieBrowser: PropTypes.bool,
  }),
})

export default recaptchaDataShape
